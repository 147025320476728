.header {
    width: 100%;
    height: 4rem;
    background-color: #e7d4b5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
}

.logo {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    color: #f05945;
    margin: 0;
}

.logo:hover{
    color: #ff4646;
}

.header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
}

.header li {
    margin: 0 1rem;
}

.header a {
    text-decoration: none;
    color: #ff9a8c;
    font-weight: bold;
    font-size: 1rem;
}

/*.header button {*/
/*    font: inherit;*/
/*    background-color: transparent;*/
/*    border: 1px solid #ff9a8c;*/
/*    color: #ff9a8c;*/
/*    font-weight: bold;*/
/*    padding: 0.5rem 1.5rem;*/
/*    border-radius: 6px;*/
/*    cursor: pointer;*/
/*}*/

/*.header a:hover {*/
/*    color: #f05945;*/
/*}*/

/*.header button:hover {*/
/*    background-color: #ff9a8c;*/
/*    color: #e7d4b5;*/
/*}*/
