.new-expense {
    margin: 5rem auto;
    width: 95%;
    max-width: 30rem;
    border-radius: 10px;
    background-color: #e7d4b5;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: left;
}


.control {
    margin-bottom: 0.5rem;
}

.control label {
    display: block;
    color: #ff9a8c;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.control input,
.control textarea{
    font: inherit;
    background-color: white;
    color: #38015c;
    border-radius: 4px;
    border: 1px solid white;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
}

.actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}



