@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
  background-image: url(/static/media/backgound-img.04e64e22.jpeg);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}


.MainNavigation_header__2lqQ1 {
    width: 100%;
    height: 4rem;
    background-color: #e7d4b5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 10%;
}

.MainNavigation_logo__1TgMx {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    color: #f05945;
    margin: 0;
}

.MainNavigation_logo__1TgMx:hover{
    color: #ff4646;
}

.MainNavigation_header__2lqQ1 ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.MainNavigation_header__2lqQ1 li {
    margin: 0 1rem;
}

.MainNavigation_header__2lqQ1 a {
    text-decoration: none;
    color: #ff9a8c;
    font-weight: bold;
    font-size: 1rem;
}

/*.header button {*/
/*    font: inherit;*/
/*    background-color: transparent;*/
/*    border: 1px solid #ff9a8c;*/
/*    color: #ff9a8c;*/
/*    font-weight: bold;*/
/*    padding: 0.5rem 1.5rem;*/
/*    border-radius: 6px;*/
/*    cursor: pointer;*/
/*}*/

/*.header a:hover {*/
/*    color: #f05945;*/
/*}*/

/*.header button:hover {*/
/*    background-color: #ff9a8c;*/
/*    color: #e7d4b5;*/
/*}*/


.Button_btn__2T0J7 {
    font: inherit;
    background-color: transparent;
    border: 1px solid #ff9a8c;
    color: #ff9a8c;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
}


.Button_btn__2T0J7:hover {
    background-color: #ff9a8c;
    color: #e7d4b5;
}

.AuthForm_auth__MPw6W {
  margin: 3rem auto;
  /*margin: -20px 0 50px;*/
  /*width: 95%;*/
  /*max-width: 25rem;*/
  border-radius: 6px;
  background-color: transparent;
  /*box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);*/
  padding: 1rem;
  text-align: center;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 80vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  -webkit-align-items: center;
          align-items: center;
}


/** {*/
/*  box-sizing: border-box;*/
/*}*/

/*.auth {*/
/*  background: #f6f5f7;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*  font-family: 'Montserrat', sans-serif;*/
/*  height: 100vh;*/
/*  margin: -20px 0 50px;*/
/*}*/

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

.AuthForm_icon__238mZ {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  border: 1px solid #FF4B2B;
  background-color: #FF4B2B;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.AuthForm_ghost__37eou {
  background-color: transparent;
  border-color: #FFFFFF;
}

form {
  background-color: #FFFFFF;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.AuthForm_container__c83gh {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25),
  0 10px 10px rgba(0,0,0,0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.AuthForm_form-container__2NNLg {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.AuthForm_sign-in-container__34zCK {
  left: 0;
  width: 50%;
  z-index: 2;
}

.AuthForm_container__c83gh.AuthForm_right-panel-active__2fbII .AuthForm_sign-in-container__34zCK {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.AuthForm_sign-up-container__u-vbn {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.AuthForm_container__c83gh.AuthForm_right-panel-active__2fbII .AuthForm_sign-up-container__u-vbn {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: AuthForm_show__nrvjD 0.6s;
          animation: AuthForm_show__nrvjD 0.6s;
}

@-webkit-keyframes AuthForm_show__nrvjD {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes AuthForm_show__nrvjD {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}

.AuthForm_overlay-container__2f-wM {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  z-index: 100;
}

.AuthForm_container__c83gh.AuthForm_right-panel-active__2fbII .AuthForm_overlay-container__2f-wM{
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.AuthForm_overlay__2H55p {
  background: #FF416C;
  background: linear-gradient(to right, #FF4B2B, #FF416C);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.AuthForm_container__c83gh.AuthForm_right-panel-active__2fbII .AuthForm_overlay__2H55p {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.AuthForm_overlay-panel__1J6zu {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.AuthForm_overlay-left__1Pggz {
  -webkit-transform: translateX(-20%);
          transform: translateX(-20%);
}

.AuthForm_container__c83gh.AuthForm_right-panel-active__2fbII .AuthForm_overlay-left__1Pggz {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.AuthForm_overlay-right__3U_u4 {
  right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.AuthForm_container__c83gh.AuthForm_right-panel-active__2fbII .AuthForm_overlay-right__3U_u4 {
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
}

.AuthForm_social-container__6bFYQ {
  margin: 20px 0;
}

.AuthForm_social-container__6bFYQ .AuthForm_icon__238mZ {
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}


/*body {*/
/*    align-items: center;*/
/*    background-color: #000;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    height: 100vh;*/
/*}*/

.ChangePassword_form__EZFFy {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    /*height: 500px;*/
    padding: 20px;
    width: 320px;

    margin: 3rem auto;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.ChangePassword_title__Gbvba {
    color: black;
    font-family: sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-top: 30px;
}

.ChangePassword_subtitle__GgwNQ {
    color: #333333;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 30px;
}








.Expenses_expenses__1MjxY {
    padding: 1rem;
    background-color: transparent;
    margin: 2rem auto;
    width: 70rem;
    max-width: 85%;
}




.ExpenseItem_expense-item__8ilYZ {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.2rem;
    background-color: #e7d4b5;
    border-radius: 6px;
}

.ExpenseItem_expense-item__description__3_c4h {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-flex-flow: column-reverse;
            flex-flow: column-reverse;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.ExpenseItem_expense-item__8ilYZ h2 {
    color: #3a3a3a;
    font-size: 1rem;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 1rem;
}

.ExpenseItem_expense-item__price__22xt9 {
    font-size: 1rem;
    font-weight: bold;
    color: #3a3a3a;
    background-color: transparent;
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 12px;
}

@media (min-width: 1141px) {
    .ExpenseItem_expense-item__description__3_c4h {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-flex: 1 1;
                flex: 1 1;
    }

    .ExpenseItem_expense-item__description__3_c4h h2 {
        font-size: 1.25rem;
    }

    .ExpenseItem_expense-item__price__22xt9 {
        font-size: 1.25rem;
        padding: 0.5rem 1.5rem;
    }
}

.ExpenseItems_expenses-list__3aV3R {
    list-style: none;
    padding: 0;
}

.ExpenseItems_expenses-list__fallback__CKx0I {
    color: white;
    font-family: 'Montserrat Proxima Nova';
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.ExpenseForm_new-expense__1hWOv {
    margin: 5rem auto;
    width: 95%;
    max-width: 30rem;
    border-radius: 10px;
    background-color: #e7d4b5;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: left;
}


.ExpenseForm_control__bKyJ3 {
    margin-bottom: 0.5rem;
}

.ExpenseForm_control__bKyJ3 label {
    display: block;
    color: #ff9a8c;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.ExpenseForm_control__bKyJ3 input,
.ExpenseForm_control__bKyJ3 textarea{
    font: inherit;
    background-color: white;
    color: #38015c;
    border-radius: 4px;
    border: 1px solid white;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
}

.ExpenseForm_actions__4VVkd {
    margin-top: 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}




.DatePicker_new-expense__controls__197ok {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: left;
}

.DatePicker_new-expense__control__38Hca label {
    display: block;
    color: #ff9a8c;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.DatePicker_new-expense__control__38Hca input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 20rem;
    max-width: 100%;
}

.DatePicker_new-expense__actions__3afU5 {
    text-align: right;
}

