/*body {*/
/*    align-items: center;*/
/*    background-color: #000;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    height: 100vh;*/
/*}*/

.form {
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    /*height: 500px;*/
    padding: 20px;
    width: 320px;

    margin: 3rem auto;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.title {
    color: black;
    font-family: sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-top: 30px;
}

.subtitle {
    color: #333333;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 30px;
}







