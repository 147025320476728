.expenses-list {
    list-style: none;
    padding: 0;
}

.expenses-list__fallback {
    color: white;
    font-family: 'Montserrat Proxima Nova';
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}
