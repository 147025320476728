.expenses {
    padding: 1rem;
    background-color: transparent;
    margin: 2rem auto;
    width: 70rem;
    max-width: 85%;
}



